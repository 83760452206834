interface Props {
  children?: React.ReactNode;
  className?: string;
  style?: any;
  id?: string;
}

export default function Card({ children, className, style, id }: Props) {
  return (
    <div
      id={id || undefined}
      className={`w-full bg-secondary rounded px-7 py-7 md:px-10 md:py-8 ${className}`}
      style={style ? { ...style } : null}
    >
      {children}
    </div>
  );
}
