import { getEnviromentByHost } from "../../utils/environment.util";


export default function Footer() {

    function getLandingPageUrlByEnvironment(){
        const environment = getEnviromentByHost();

        switch(environment){
            case 'LOCAL':
                return 'http://localhost:8080';
            case 'DEVELOPMENT':
                return 'https://dev.da2x.com.br';
            case 'PRODUCTION':
                return 'https://www.da2x.com.br';
        }
    }

    return (
        <footer className="flex flex-col items-center font-raleway bg-dark py-10 mt-10">
            <h3 className="text-white font-bold text-xl">DA2x</h3>
            <div
                className="w-32 bg-blue rounded mt-5"
                style={{ height: "8px" }}
            ></div>

            <a href={`${getLandingPageUrlByEnvironment()}/termos-de-uso`} target="_blank">  <p className="text-silver mt-10 text-sm font-bold">Termos e condições de uso</p></a>
            <a href={`${getLandingPageUrlByEnvironment()}/politica-de-privacidade`} target="_blank"> <p className="text-silver mt-5 text-sm font-bold">Política de privacidade</p></a>
            <a href='https://wa.me/5547999774436' target={'_blank'} rel={"noreferrer"}> <p className="text-silver mt-5 text-sm font-bold">Contato</p></a>
            <p className="text-silver mt-5 text-center px-10 md:px-52 text-sm">DA2x é um serviço pago, baseado em assinatura e sujeito a termos e condições. O serviço DA2x é comercializado pelo CNPJ 49.639.607/0001-05
            </p>
        </footer>
    );
}