import { createBrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import CadastroSucesso from "../pages/Cadastro/Sucesso";
import React, { Suspense } from "react";
import Loader from "../components/Loader";
import ClassRepositoryApi from "../repositories/api/ClassRepositoryApi";
import CourseRespositoryApi from "../repositories/api/CourseRepositoryApi";
import { EbookRepositoryApi } from "../repositories/api/EbookRepositoryApi";
import { PetitionRepositoryApi } from "../repositories/api/PetitionRepositoryApi";
import { PlanRepositoryApi } from "../repositories/api/PlanRepositoryApi";
import { UserRepositoryApi } from "../repositories/api/UserRepositoryApi";
import { AuthServiceApi } from "../services/api/AuthServiceApi";
import AwaitingRenewalPayment from "../pages/AwaitingPayment";

const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));
const ForgetPassword = React.lazy(() => import("../pages/ForgetPassword"));
const Cadastro = React.lazy(() => import("../pages/Cadastro"));
const CanceledSubscription = React.lazy(
  () => import("../pages/CanceledSubscription")
);
const CourseDetails = React.lazy(() => import("../pages/CourseDetails"));
const Courses = React.lazy(() => import("../pages/Courses"));
const Ebooks = React.lazy(() => import("../pages/Ebooks"));
const ErrorPage = React.lazy(() => import("../pages/ErrorPage"));
const ExpiredSubscription = React.lazy(
  () => import("../pages/ExpiredSubscription")
);
const MyAccount = React.lazy(() => import("../pages/MyAccount"));
const NewPassword = React.lazy(() => import("../pages/NewPassword"));
const PetitionDetails = React.lazy(() => import("../pages/PetitionDetails"));
const Petitions = React.lazy(() => import("../pages/Petitions"));
const Watch = React.lazy(() => import("../pages/Watch"));

const authServiceApi = new AuthServiceApi();
const courseRepositoryApi = new CourseRespositoryApi();
const ebookRepositoryApi = new EbookRepositoryApi();
const planRepositoryApi = new PlanRepositoryApi();
const petitionRepositoryApi = new PetitionRepositoryApi();
const classRepositoryApi = new ClassRepositoryApi();
const userRepositoryApi = new UserRepositoryApi();

const Fallback = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Loader />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={<Fallback />}>
        <Login authService={authServiceApi} />
      </Suspense>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <Suspense fallback={<Fallback />}>
        <ForgetPassword userRepository={userRepositoryApi} />
      </Suspense>
    ),
  },
  {
    path: "/new-password/:code",
    element: (
      <Suspense fallback={<Fallback />}>
        <NewPassword userRepository={userRepositoryApi} />
      </Suspense>
    ),
  },
  {
    path: "/cadastro",
    element: (
      <Suspense fallback={<Fallback />}>
        <Cadastro
          planRepository={planRepositoryApi}
          authService={authServiceApi}
        />
      </Suspense>
    ),
  },
  {
    path: "/cadastro/sucesso",
    element: (
      <Suspense fallback={<Fallback />}>
        <CadastroSucesso />,
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <Navigate to={"/home"} replace={true} />,
  },
  {
    path: "/expired-subscription",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <ExpiredSubscription
            planRepository={planRepositoryApi}
            userRepository={userRepositoryApi}
          />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/canceled-subscription",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <CanceledSubscription
            planRepository={planRepositoryApi}
            userRepository={userRepositoryApi}
          />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/awaiting-payment",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <AwaitingRenewalPayment />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <Home
            courseRepository={courseRepositoryApi}
            ebookRepository={ebookRepositoryApi}
            petitionRepository={petitionRepositoryApi}
          />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/courses/:courseId",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <CourseDetails courseRepository={courseRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/courses/:courseId/class/:classId",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <Watch classRepository={classRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/courses",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <Courses courseRepository={courseRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/materiais",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <Ebooks ebooksRepository={ebookRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/petitions",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <Petitions petitionsRepository={petitionRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/petitions/:petitionId",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          {" "}
          <PetitionDetails petitionRepository={petitionRepositoryApi} />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/my-account",
    element: (
      <ProtectedRoute>
        <Suspense fallback={<Fallback />}>
          <MyAccount
            userRepository={userRepositoryApi}
            planRepository={planRepositoryApi}
          />
        </Suspense>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
]);

export { router };
