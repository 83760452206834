import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./config/router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWRConfig } from "swr";
import { FacebookPixel } from "./components/FacebookPixel";
import GoogleAnalytics from "./components/GoogleAnalytics";
import { api, createApi } from "./config/api";

createApi();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

function App() {
  return (
    <>
      <SWRConfig
        value={{
          fetcher: (url: string) => api.get(url).then((res) => res.data),
        }}
      >
        <FacebookPixel />
        <GoogleAnalytics />
        <ToastContainer />
        <RouterProvider router={router} />
      </SWRConfig>
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
