import axios from "axios";
import { API_URL } from "../../config/constants";
import { CreateUserDTO } from "../../models/CreateUserDTO";
import { LoginResponse } from "../../models/LoginResponse";
import { Subscription } from "../../models/Subscription";
import { IAuthService } from "../IAuthService";



export class AuthServiceApi implements IAuthService{
    async login(email: string, password: string): Promise<LoginResponse> {
    
       try{
        const response = await axios.post(`${API_URL}/login`, { email, password });
        console.log(response);
 
        return response.data

       }catch(err: any){
        console.log(err)
        throw new Error(err.response.data.message || 'Erro ao realizar login')
       }
    }

    async signup(userDTO: CreateUserDTO): Promise<Subscription> {
        const response = await axios.post(`${API_URL}/cadastro`, userDTO);
        const subscription: Subscription = response.data;
        return subscription;
    }
}