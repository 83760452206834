import { Helmet } from "react-helmet";

export default function GoogleAnalytics() {
  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-MPW6J18TTN"
      ></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-MPW6J18TTN');
        `}
      </script>
    </Helmet>
  );
}
