import { api } from "../../config/api";
import { Course } from "../../models/Course";
import { WatchingListItem } from "../../models/WatchingListItem";
import {
  GenerateCertificateResponse,
  GetCourseDetailsResponse,
  ICourseRepository,
} from "../ICourseRepository";

export default class CourseRespositoryApi implements ICourseRepository {
  async getAllCourses(): Promise<Course[]> {
    const response = await api.get("/courses");
    return response.data;
  }
  async getCourseDetails(id: string): Promise<GetCourseDetailsResponse> {
    const response = await api.get(`/courses/${id}`);
    return response.data;
    // return {
    //     course: response.data,
    //     watchingClassId: response.data.watchingClassId,
    //     percentageCompleted:  response.data.percentageCompleted,
    //     watchedClassesIds: response.data.watchedClassesIds,
    //     certificateId: response.data.certificateId
    // };
  }
  async getWatchingList(): Promise<WatchingListItem[]> {
    const response = await api.get("/watching-list");
    return response.data;
  }
  async getRecentCourses(): Promise<Course[]> {
    const response = await api.get("/recent-courses");
    return response.data;
  }

  async generateCertificate(
    courseId: string
  ): Promise<GenerateCertificateResponse> {
    const response = await api.post(`/certificates/${courseId}`);
    return response.data;
  }
}
