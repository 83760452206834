import { api } from "../../config/api";
import { Petition } from "../../models/Petition";
import { GetPetitionsResponse, GetPetitionUrlResponse, IPetitionRepository } from "../IPetitionRepository";


export class PetitionRepositoryApi implements IPetitionRepository{
    async getPetitions(limit: number, offset: number, search?: string | undefined): Promise<GetPetitionsResponse> {
        
        let path = `/petitions?offset=${offset}&limit=${limit}`

        if(search) path = `${path}&search=${search}`

        const response = await api.get(path);
        return response.data;
    }

    async getPetitionUrl(petitionFilePath: string): Promise<GetPetitionUrlResponse> {
        const response = await api.post(`/petition-url`, { filePath: petitionFilePath });
        return response.data;
    }
}