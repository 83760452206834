import { api } from "../../config/api";
import { ClassComment } from "../../models/Comment";
import { CommentDTO, GetAttachmentUrlResponse, GetClassDetailsResponse, GetCommentsResponse, IClassRepository } from "../IClassRepository";



export default class ClassRepositoryApi implements IClassRepository{
    async getClassDetails(classId: string): Promise<GetClassDetailsResponse> {
        const response = await api.get(`/classes/${classId}`);
        return response.data;
    }

    async createWatchingClass(classId: string): Promise<void> {
        await api.post(`/classes/${classId}/watching-class`)
    }

    async createWatchHistory(classId: string): Promise<void> {
        await api.post(`/classes/${classId}/watch-history`)
    }

    async getAttachmentUrl(attachmentFilePath: string): Promise<GetAttachmentUrlResponse> {
        const response = await api.post(`/attachment-url`, { filePath: attachmentFilePath });
        return response.data;
    }

    async getComments(classId: string, offset: number): Promise<GetCommentsResponse> {
        const response = await api.get(`/class/${classId}/comment?offset=${offset}`);
        return response.data;
    }

    async getCommentAnswers(commentId: string): Promise<ClassComment[]> {
        const response = await api.get(`/class/comment/${commentId}/answers`);
        return response.data;
    }

    async sendComment(dto: CommentDTO): Promise<ClassComment> {
        const response = await api.post(`/class/comment`, dto);
        return response.data;
    }

    async deleteComment(commentId: string): Promise<void> {
        await api.delete(`/class/comment/${commentId}`);
    }
}
