import { getEnviromentByHost } from "../utils/environment.util";

const MEDIA_CARD_WIDTH = 290;
const MEDIA_CARD_HEIGHT = 420;

const ENVIROMENT = getEnviromentByHost();

let API_URL: string;

if (ENVIROMENT === "DEVELOPMENT") API_URL = "https://dev-api.da2x.com.br";
if (ENVIROMENT === "PRODUCTION") API_URL = "https://api.da2x.com.br";
if (ENVIROMENT === "LOCAL") API_URL = "http://localhost:3001";

export { MEDIA_CARD_WIDTH, MEDIA_CARD_HEIGHT, API_URL };
