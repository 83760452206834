import { User } from "../models/User";

export const isAuthenticated = () => {
  const user = localStorage.getItem("user");

  return user ? true : false;
};

export const getUser = (): User => {
  const u = JSON.parse(localStorage.getItem("user") || "{}");

  const user: User = {
    id: u.id,
    firstName: u.firstName,
    lastName: u.lastName,
    pictureUrl: u.pictureUrl,
  };

  return user;
};

export const logoff = () => {
  localStorage.removeItem("user");
  window.location.reload();
};

export const updateLocalUser = (user: User) => {
  const u = JSON.parse(localStorage.getItem("user") || "{}");
  localStorage.setItem(
    "user",
    JSON.stringify({
      ...user,
      token: u.token,
      refreshToken: u.refreshToken,
    })
  );
};
