import Footer from "../Footer";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";

type Props = {
  children?: React.ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <>
      <Header />
      <main className="mt-24 md:mt-32 mx-5 lg:mx-auto lg:max-w-7xl 2xl:max-w-8xl font-raleway pb-10">
        <ScrollToTop />
        {children}
      </main>
      <Footer />
    </>
  );
}
