import { api } from "../../config/api";
import { Ebook } from "../../models/Ebook";
import { GetEbookUrlResponse, IEbookRepository } from "../IEbookRepository";


export class EbookRepositoryApi implements IEbookRepository {
    async getEbooks(): Promise<Ebook[]> {
        const response = await api.get('/ebooks');
        return response.data;
    }

    async getEbookUrl(ebookFilePath: string): Promise<GetEbookUrlResponse> {
        const response = await api.post(`/ebook-url`, { filePath: ebookFilePath });
        return response.data;
    }
}