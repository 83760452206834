import { MouseEventHandler } from "react";

type ButtonProps = {
    className?: string;
    children: any;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'button' | 'submit' | 'reset';
}


export default function Button(props : ButtonProps){
    return (
        <button
         disabled={props.disabled}
         onClick={props.onClick}
         className={`bg-blue text-white py-2 md:py-3 px-5 md:px-8 rounded font-semibold text-sm md:text-base font-raleway hover:opacity-95 ${props.disabled ? 'opacity-60 hover:opacity-60' : ''} ${props.className}`}
         type={props.type ? props.type : 'button'}
         >
            {props.children}
        </button>
    );
}