import { useState } from 'react';
import { Tab } from '../models/Tab';

export const useHeader = () => {
    
    const [mobileMenuPosition, setMobileMenuPosition] = useState('-right-96');


    function isActiveTab(tab: Tab): boolean{
        return window.location.pathname.search(tab.path) > -1
    }

    function openMobileMenu() {
        setMobileMenuPosition('-right-0');
    }

    function closeMobileMenu() {
        setMobileMenuPosition('-right-full');
    }

    return {
        mobileMenuPosition,
        isActiveTab,
        openMobileMenu,
        closeMobileMenu
    }
}