import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import { useSearchParams } from "react-router-dom";
import Button from "../../components/Button";

export default function AwaitingRenewalPayment() {
  let [searchParams] = useSearchParams();

  const invoiceUrl = searchParams.get("invoiceUrl");

  return (
    <Layout>
      <Helmet>
        <title>Assinatura aguardando pagamento | DA2X</title>
      </Helmet>
      <div className="text-silver max-w-xl m-auto">
        <Card className="space-y-3">
          <h1 className="text-silver text-xl md:text-2xl font-bold">
            Aguardando pagamento
          </h1>
          <p className="">
            Estamos aguardando a confirmação do pagamento da sua fatura.
          </p>

          <p className="">
            Se você já efetuou o pagamento, aguarde até nosso email de
            confirmação de pagamento.
          </p>

          <a href={invoiceUrl || ""} target="_blank">
            <Button className="w-full mt-10">Visualizar fatura</Button>
          </a>
        </Card>
      </div>
    </Layout>
  );
}
