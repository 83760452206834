import { api } from "../../config/api";
import { API_URL } from "../../config/constants";
import { PAYMENT_METHODS } from "../../models/PaymentMethods";
import { DatabaseSubscription, Subscription } from "../../models/Subscription";
import { getUser } from "../../services/auth";
import {
  GetMyAccountDataResponse,
  IUserRepository,
  RenewSubscriptionBody,
  UpdateUserDataBody,
} from "../IUserRepository";

export class UserRepositoryApi implements IUserRepository {
  async getMyAccountData(): Promise<GetMyAccountDataResponse> {
    const response = await api.get(`${API_URL}/user/my-account-data`);
    return response.data;
  }

  async updateUserData(body: UpdateUserDataBody): Promise<void> {
    await api.patch(`${API_URL}/user`, body);
  }

  async forgetPassword(email: string): Promise<any> {
    const response = await api.post(`${API_URL}/forget-password`, { email });
    return response.data;
  }

  async newPassword(code: string, password: string): Promise<any> {
    await api.post(`${API_URL}/new-password`, { code, password });
  }

  async updatePlan(newPlanId: string): Promise<Subscription> {
    const response = await api.patch(`${API_URL}/user/subscription`, {
      newPlanId,
    });
    return response.data;
  }

  async updatePaymentMethod(
    newPaymentMethod: PAYMENT_METHODS
  ): Promise<Subscription> {
    const response = await api.patch(`${API_URL}/user/subscription`, {
      newBilling: { paymentMethod: newPaymentMethod },
    });
    return response.data;
  }

  // async renewSubscription(body: RenewSubscriptionBody): Promise<Subscription> {
  //   const response = await api.post(`${API_URL}/user/renew-subscription`, body);
  //   return response.data;
  // }
  async renewSubscription(paymentMethod: PAYMENT_METHODS): Promise<void> {
    await api.post(`${API_URL}/user/renew-subscription`, {
      paymentMethod,
    });
    // return response.data;
  }

  async cancelSubscription(): Promise<DatabaseSubscription> {
    const response = await api.post(`${API_URL}/user/cancel-subscription`);
    return response.data;
  }

  async updateAvatar(formData: FormData): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    const response = await api.patch(`${API_URL}/user/avatar`, formData, {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response.data;
  }
}
