import { AiOutlineMenu } from "react-icons/ai";
import { useHeader } from "./hooks/useHeader";
import { Tab } from "./models/Tab";
import { Link } from "react-router-dom";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { useState } from "react";
import { getUser, logoff } from "../../services/auth";
import logo from "../../logo.png";

const tabs: Tab[] = [
  {
    path: "/home",
    label: "Home",
  },
  {
    path: "/courses",
    label: "Cursos",
  },
  {
    path: "/materiais",
    label: "Materiais",
  },
  {
    path: "/petitions",
    label: "Petições",
  },
];

export default function Header() {
  const user = getUser();
  const [dropDownMenuIsOpen, setDropDownMenuIsOpen] = useState(false);
  const { mobileMenuPosition, openMobileMenu, closeMobileMenu, isActiveTab } =
    useHeader();

  return (
    <header className="w-full bg-secondary py-3 fixed top-0 mb-32 shadow-lg z-50">
      <div className="flex justify-between items-center mx-5 md:mx-10">
        <h2 className="text-white font-bold text-2xl md:text-3xl font-raleway">
          <Link to={"/"}>
            <img alt="da2x logo" src={logo} className="w-32" />
          </Link>
        </h2>
        <nav className="hidden md:flex h-full gap-7 list-none text-silver font-raleway font-semibold">
          {tabs.map((tab: any, index: number) =>
            isActiveTab(tab) ? (
              <li key={index} className="text-white font-bold relative">
                <Link to={tab.path}>{tab.label}</Link>
                <div className="w-full h-1 bg-blue absolute rounded -bottom-5" />
              </li>
            ) : (
              <li
                className="font-medium opacity-70 hover:opacity-100 transition"
                key={index}
              >
                <Link to={tab.path}>{tab.label}</Link>
              </li>
            )
          )}
        </nav>
        <div className="hidden md:flex md:items-center">
          <div className="w-10 h-10 bg-silver p-0.5 rounded-full cursor-pointer">
            <img
              src={user.pictureUrl || "/default_user.jpg"}
              className="w-full h-full rounded-full object-cover"
            />
          </div>

          <div
            className="flex items-center cursor-pointer relative shadow-lg"
            onClick={() => setDropDownMenuIsOpen((value) => !value)}
          >
            <span className="text-silver text-raleway font-bold ml-4 capitalize">
              {user.firstName.toLowerCase()}
            </span>
            {dropDownMenuIsOpen ? (
              <MdOutlineArrowDropUp className="text-blue text-2xl mt-1" />
            ) : (
              <MdOutlineArrowDropDown className="text-blue text-2xl mt-1" />
            )}

            <div
              className={`absolute z-50 bg-secondary -bottom-32 -left-32 w-48 text-silver rounded ${
                dropDownMenuIsOpen ? "block" : "hidden"
              }`}
              style={{ transition: "2s" }}
            >
              <nav className="list-none text-sm">
                <Link to={"/my-account"}>
                  <li className="flex items-center font-semibold hover:bg-gray-800 transition px-4 py-3 rounded">
                    <FaUserAlt className="mr-5 text-sm text-blue" />
                    Minha conta
                  </li>
                </Link>
                <li
                  onClick={logoff}
                  className="flex items-center font-semibold hover:bg-gray-800 transition px-3 py-3 rounded"
                >
                  <BiLogOut className="mr-5 text-lg text-blue" />
                  <span>Sair</span>
                </li>
              </nav>

              <MdOutlineArrowDropUp className="absolute text-secondary right-0 -top-7 text-5xl" />
            </div>
          </div>
        </div>
        <AiOutlineMenu
          onClick={openMobileMenu}
          className="block md:hidden text-white cursor-pointer text-2xl"
        />
      </div>

      <div
        onClick={closeMobileMenu}
        className={`md:hidden absolute top-0 w-screen h-screen flex justify-end transparent ${mobileMenuPosition}`}
        style={{ transition: "0.3s" }}
      >
        <div
          onClick={(ev) => ev.stopPropagation()}
          className={`block md:hidden h-full bg-secondary w-9/12 p-5 shadow-lg`}
        >
          <div className="w-full flex justify-start items-center">
            <div className="w-10 h-10 bg-silver p-0.5 rounded-full cursor-pointer">
              <img
                src={user.pictureUrl || "/default_user.jpg"}
                className="w-full h-full rounded-full object-cover"
              />
            </div>

            <h1 className="ml-3 text-silver font-bold text-xl capitalize">
              {user.firstName.toLowerCase()}
            </h1>
          </div>

          <nav className="flex flex-col gap-4 text-base list-none text-silver font-raleway font-semibold mt-8">
            {tabs.map((tab: any, index: number) => (
              <li key={index}>
                <Link to={tab.path}>{tab.label}</Link>
              </li>
            ))}
            {/* <li><a href="/">Home</a></li>
                        <li><a href="/">Cursos</a></li>
                        <li><a href="/">Materiais complementares</a></li>
                        <li><a href="/">Minha conta</a></li> */}
            <li>
              <a href="/my-account">Minha conta</a>
            </li>
            <li className="cursor-pointer" onClick={logoff}>
              Sair
            </li>
          </nav>
        </div>
      </div>
    </header>
  );
}
