import axios, { AxiosInstance } from "axios";
import { logoff } from "../services/auth";
import { API_URL } from "./constants";

let api: AxiosInstance;

export function createApi() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  api = axios.create({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
    baseURL: API_URL,
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // console.log(error)
      const originalConfig = error.config;
      const access_token = user.token;

      if (
        error.response.status === 401 &&
        access_token &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        await refreshToken(error);
        window.location.reload();
        return api(originalConfig);
      }

      if (
        error.response.status === 403 &&
        error.response.data.expiredSubscription
      ) {
        if (error.response.data.invoiceUrl)
          window.location.href = `/awaiting-payment?invoiceUrl=${error.response.data.invoiceUrl}`;
        else
          window.location.href = `/expired-subscription?planTitle=${error.response.data.plan.title}&planPrice=${error.response.data.plan.price}&planCycle=${error.response.data.plan.cycle}`;
      }

      if (
        error.response.status === 403 &&
        error.response.data.canceledSubscription
      ) {
        window.location.href = `/canceled-subscription`;
      }

      if (
        error.response.status === 403 &&
        error.response.data.renewalSubscription
      ) {
        window.location.href = `/awaiting-payment?invoiceUrl=${error.response.data.invoiceUrl}`;
      }
      return Promise.reject(error);
    }
  );

  async function refreshToken(error: any) {
    // const userEmail = localStorage.getItem("email");
    // const refreshToken = localStorage.getItem("refreshToken");

    try {
      const response = await axios.post(`${API_URL}/refresh-token`, {
        email: user.email,
        refreshToken: user.refreshToken,
      });
      user.token = response.data.accessToken;
      localStorage.setItem("user", JSON.stringify(user));
    } catch (err) {
      console.log(err);
      logoff();
      throw error;
    }
  }
}

export { api };
