


export function getEnviromentByHost(): string{
    const host = window.location.host;
    if(host === 'dev-app.da2x.com.br') return 'DEVELOPMENT';
    if(host === 'app.da2x.com.br') return 'PRODUCTION';
    return 'LOCAL';
}


