import axios from "axios";
import { API_URL } from "../../config/constants";
import { Plan } from "../../models/Plan";
import { IPlanRepository } from "../IPlanRepository";


export class PlanRepositoryApi implements IPlanRepository{
    async findAll(): Promise<Plan[]> {
        const response = await axios.get(`${API_URL}/plans`);
        return response.data;
    }
}